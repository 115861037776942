import { mapState, mapGetters } from 'vuex';

import {
  addFoundationAccess,
  createFoundationByType,
  getFoundationByType,
  removeFoundationAccess,
  updateFoundationType,
  updateFoundationVisibility,
  subscribeToFoundationType,
} from '@/services/foundation.js';
import { generateID } from '@/helpers/data';
import cloneDeep from 'lodash.clonedeep';
import { titlecase, getError } from '@/helpers/utils';

export default {
  data() {
    return {
      foundationData: {},
      prevData: null,
      isFetchingData: false,
      hasAccess: true,
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
  },
  created() {
    this.getFoundationData();
  },
  mounted() {
    this.subscribeToFoundationData();
  },
  methods: {
    subscribeToFoundationData() {
      const companyId = this.activeCompany.id;
      subscribeToFoundationType({
        type: this.foundationType,
        companyId,
      }).subscribe({
        next: (res) => {
          if (res.data.foundation.length) {
            this.foundationData = res.data.foundation[0];
            this.prevData = res.data.foundation[0];
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.setFoundationSeedData();
          } else {
            this.hasAccess = false;
          }
        },
        error(error) {
          console.error(error);
        },
      });
    },
    getFoundationData() {
      this.isFetchingData = true;
      getFoundationByType({
        type: this.foundationType,
        companyId: this.activeCompany.id,
      })
        .then((res) => {
          this.isFetchingData = false;
          if (res.data.foundation.length) {
            this.foundationData = res.data.foundation[0];
            this.prevData = res.data.foundation[0];
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.setFoundationSeedData();
          } else {
            this.hasAccess = false;
          }
        })
        .finally(() => {
          this.hasMounted = true;
        });
    },
    setFoundationSeedData() {
      this.isFetchingData = true;
      let data = {
        type: this.foundationType,
        description: '',
        companyId: this.activeCompany.id,
        assumption: 'unverified',
      };
      if (this.foundationType == 'culture') {
        data = {
          ...data,
          foundation_items: {
            data: [
              {
                label: 'Ideal Culture',
                description: '',
              },
              {
                label: 'How we achieve this in practice',
                description: '',
              },
            ],
          },
        };
      }
      createFoundationByType(data).then((res) => {
        this.foundationData = cloneDeep(res.data.insert_foundation_one);
        this.prevData = cloneDeep(res.data.insert_foundation_one);
        this.isFetchingData = false;
      });
    },
    updateData({ key, value }) {
      const set = { [key]: value };
      this.foundationData[key] = value;
      updateFoundationType({
        type: this.foundationType,
        companyId: this.activeCompany.id,
        set,
      })
        .then((res) => {
          if (!res.data.update_foundation.returning.length) {
            throw { error: 'unauthorized' };
          }
        })
        .catch((e) => {
          this.foundationData[key] = this.prevData[key];
          if (e.error === 'unauthorized') {
            this.$toast({
              title: 'Unauthorized',
              description: `You are not allowed to edit this information`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
            return;
          }
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating ${titlecase(
              this.foundationType.split('_').join(' ')
            )}, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    updateAccess(user) {
      const accessIndex = this.foundationData.foundation_assignments.findIndex(
        (access) => access.user.id == user.id
      );
      if (accessIndex === -1) {
        const tempId = generateID(4);
        this.foundationData.foundation_assignments.push({
          id: tempId,
          user,
        });
        this.addAccess(user, tempId);
      } else {
        this.removeAccess(accessIndex);
      }
    },
    async addAccess(user, tempId) {
      const data = {
        foundationId: this.foundationData.id,
        userId: user.id,
      };
      const accessIndex = this.foundationData.foundation_assignments.findIndex(
        (access) => access.id == tempId
      );
      try {
        const res = await addFoundationAccess(data);
        this.foundationData.foundation_assignments[accessIndex] =
          res.data.insert_foundation_assignment_one;
        this.prevData.foundation_assignments = cloneDeep(
          this.foundationData.foundation_assignments
        );
      } catch (err) {
        const error = Object.assign({}, err);

        this.foundationData.foundation_assignments = cloneDeep(
          this.prevData.foundation_assignments
        );
        getError(error.message, 'assigning user');
      }
    },
    async removeAccess(accessIndex) {
      const access = this.foundationData.foundation_assignments.splice(
        accessIndex,
        1
      )[0];
      try {
        await removeFoundationAccess(access.id);
        this.prevData.foundation_assignments = cloneDeep(
          this.foundationData.foundation_assignments
        );
      } catch (e) {
        this.foundationData.foundation_assignments = cloneDeep(
          this.prevData.foundation_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while removing member access, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateVisibility(users) {
      this.isFetchingData = true;
      const newUsers = users.filter(
        (user) =>
          !this.foundationData.foundation_visibilities.find(
            (visibility) => visibility.userId === user.id
          )
      );
      const removedVisibilities =
        this.foundationData.foundation_visibilities.filter(
          (visibility) => !users.find((user) => visibility.userId === user.id)
        );
      const data = {
        addVisibility: newUsers.map((user) => ({
          userId: user.id,
          foundationId: this.foundationData.id,
        })),
        removedVisibilities: removedVisibilities.map(
          (visibility) => visibility.id
        ),
      };
      const visibilityData = cloneDeep(
        this.foundationData.foundation_visibilities
      );
      try {
        const res = await updateFoundationVisibility(data);
        res.data.delete_foundation_visibility.returning.forEach(
          (deletedVisibility) => {
            const deletedVisibilityIndex = visibilityData.find(
              (visibility) => visibility.id === deletedVisibility.id
            );
            visibilityData.splice(deletedVisibilityIndex, 1);
          }
        );
        visibilityData.push(...res.data.insert_foundation_visibility.returning);
        this.foundationData.foundation_visibilities = cloneDeep(visibilityData);
        this.prevData.foundation_visibilities = cloneDeep(visibilityData);
        this.isFetchingData = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
