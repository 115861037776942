import { mapState, mapGetters } from 'vuex';

import {
  addBusinessModelAccess,
  createBusinessModelByType,
  getBusinessModelByType,
  removeBusinessModelAccess,
  updateBusinessModel,
  updateBusinessModelVisibility,
  subscribeToBusinessModelType,
} from '@/services/business-model.js';
import { generateID } from '@/helpers/data';
import cloneDeep from 'lodash.clonedeep';

import { titlecase, getError } from '@/helpers/utils';

export default {
  data() {
    return {
      businessModelData: {},
      prevData: {},
      isFetchingData: false,
      hasAccess: true,
      isUpdatingMetadata: false,
      businessChannels: [],
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyOwner', 'isCompanyAdmin']),
  },
  created() {
    this.getBusinessModelData();
  },
  mounted() {
    this.subscribeToBusinessModelData();
  },
  methods: {
    subscribeToBusinessModelData() {
      subscribeToBusinessModelType({
        type: this.businessModelType,
        companyId: this.activeCompany.id,
      }).subscribe({
        next: (res) => {
          if (res.data.business_model.length) {
            this.businessModelData = res.data.business_model[0];
            this.prevData = res.data.business_model[0];
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.setBusinessModelSeedData();
          } else {
            this.hasAccess = false;
          }
        },
        error(error) {
          console.error(error);
        },
      });
    },
    getBusinessModelData() {
      this.isFetchingData = true;
      getBusinessModelByType({
        type: this.businessModelType,
        companyId: this.activeCompany.id,
      })
        .then((res) => {
          this.isFetchingData = false;
          if (res.data.business_model.length) {
            this.businessModelData = res.data.business_model[0];
            this.prevData = res.data.business_model[0];
          } else if (this.isCompanyOwner || this.isCompanyAdmin) {
            this.setBusinessModelSeedData();
          } else {
            this.hasAccess = false;
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.hasMounted = true;
        });
    },
    setBusinessModelSeedData() {
      this.isFetchingData = true;
      const data = {
        type: this.businessModelType,
        description: '',
        companyId: this.activeCompany.id,
        assumption: 'unverified',
      };
      createBusinessModelByType(data).then((res) => {
        this.businessModelData = res.data.insert_business_model_one;
        this.prevData = res.data.insert_business_model_one;
        this.isFetchingData = false;
      });
    },
    updateData({ key, value }) {
      const set = { [key]: value };
      this.businessModelData[key] = value;
      updateBusinessModel({
        type: this.businessModelType,
        companyId: this.activeCompany.id,
        set,
      })
        .then((res) => {
          if (!res.data.update_business_model.returning.length) {
            throw { error: 'unauthorized' };
          }
        })
        .catch((e) => {
          this.businessModelData[key] = this.prevData[key];
          if (e.error === 'unauthorized') {
            this.$toast({
              title: 'Unauthorized',
              description: `You are not allowed to edit this information`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
            return;
          }
          this.$toast({
            title: 'An error occurred.',
            description: `Error while updating ${titlecase(
              this.businessModelType.split('_').join(' ')
            )}, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    updateAccess(user) {
      const accessIndex =
        this.businessModelData.business_model_assignments.findIndex(
          (access) => access.user.id == user.id
        );
      if (accessIndex === -1) {
        const tempId = generateID(4);
        this.businessModelData.business_model_assignments.push({
          id: tempId,
          user,
        });
        this.addAccess(user, tempId);
      } else {
        this.removeAccess(accessIndex);
      }
    },
    async addAccess(user, tempId) {
      const data = {
        businessModelId: this.businessModelData.id,
        userId: user.id,
      };
      const accessIndex =
        this.businessModelData.business_model_assignments.findIndex(
          (access) => access.id == tempId
        );
      try {
        const res = await addBusinessModelAccess(data);
        this.businessModelData.business_model_assignments[accessIndex] =
          res.data.insert_business_model_assignment_one;
        this.prevData.business_model_assignments = cloneDeep(
          this.businessModelData.business_model_assignments
        );
      } catch (err) {
        const error = Object.assign({}, err);

        this.businessModelData.business_model_assignments = cloneDeep(
          this.prevData.business_model_assignments
        );

        getError(error.message, 'assigning user');
      }
    },
    async removeAccess(accessIndex) {
      const access = this.businessModelData.business_model_assignments.splice(
        accessIndex,
        1
      )[0];
      try {
        await removeBusinessModelAccess(access.id);
        this.prevData.business_model_assignments = cloneDeep(
          this.businessModelData.business_model_assignments
        );
      } catch (e) {
        this.c.business_model_assignments = cloneDeep(
          this.prevData.business_model_assignments
        );
        this.$toast({
          title: 'An error occurred.',
          description: `Error while removing member access, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async updateVisibility(users) {
      this.isUpdatingMetadata = true;
      const newUsers = users.filter(
        (user) =>
          !this.businessModelData.business_model_visibilities.find(
            (visibility) => visibility.userId === user.id
          )
      );
      const removedVisibilities =
        this.businessModelData.business_model_visibilities.filter(
          (visibility) => !users.find((user) => visibility.userId === user.id)
        );
      const data = {
        addVisibility: newUsers.map((user) => ({
          userId: user.id,
          businessModelId: this.businessModelData.id,
        })),
        removedVisibilities: removedVisibilities.map(
          (visibility) => visibility.id
        ),
      };
      const visibilityData = cloneDeep(
        this.businessModelData.business_model_visibilities
      );
      try {
        const res = await updateBusinessModelVisibility(data);
        res.data.delete_business_model_visibility.returning.forEach(
          (deletedVisibility) => {
            const deletedVisibilityIndex = visibilityData.find(
              (visibility) => visibility.id === deletedVisibility.id
            );
            visibilityData.splice(deletedVisibilityIndex, 1);
          }
        );
        visibilityData.push(
          ...res.data.insert_business_model_visibility.returning
        );
        this.businessModelData.business_model_visibilities =
          cloneDeep(visibilityData);
        this.prevData.business_model_visibilities = cloneDeep(visibilityData);
        this.isUpdatingMetadata = false;
      } catch (e) {
        console.log(e);
      }
    },
    // getChannels(topic) {
    //   getAllChannels({
    //     businessModelId: this.businessModelData.id,
    //     topic: topic,
    //   }).then((res) => {
    //     this.businessChannels = res.data.channel;
    //     return res.data.channel;
    //   });
    // },
  },
};
